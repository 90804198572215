import { createApp } from 'vue'
import App from './Image.vue'

document.addEventListener("turbo:load", () => {
  const mountEl = document.querySelector("#image");
  if (mountEl){
    const app = createApp(App, { ...mountEl.dataset, innerHtml: mountEl.innerHTML })
    app.mount('#image')
  }
});

