<template lang="pug">
div
  img.img-fluid.rounded(:src="src" :alt="alt" @click="visible=true" style="cursor: pointer;")
  vue-easy-lightbox(
    :visible="visible"
    :index="0"
    :imgs="[{src: src, alt: alt, title: alt}]"
    @hide="visible=false"
  )
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
export default {
  components: {
    VueEasyLightbox
  },
  props: ["src", "alt"],
  data () {
    return {
      visible: false,
    }
  }
}
</script>